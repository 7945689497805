<template>
  <div class="v-arena" :class="`v-arena--${arenaSlug}`">
    <Topbar
      v-if="arena && arena.name"
      :data="arena"
      topbar-component="TopbarArena"
    />
    <div class="v-arena__wrapper" v-if="asyncDataReady">
      <img class="v-arena__bg" :src="cover" :alt-text="arena && arena.name" />
      <div class="v-arena__activities-wrapper">
        <h1 class="v-arena__title">{{ $t('ARENA_WHERE_YOU_GO') }}</h1>
        <Card
          class="v-arena__activities-item"
          v-for="item in activities"
          :key="item.id"
          :item="item"
          :title="item.name"
          :image="item.cover"
          :slug="item.slug"
          :link="`/activity/${arenaSlug}/${item.slug}`"
          :progress="activitiesProgress[item.id]"
          :items-count="countMap[item.id]"
          :disabled="!enabledActivities[item.id]"
        />
      </div>
    </div>
  </div>
  <Curtain />
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { getStructuresChildren } from '@/core/services/structures'

import {
  useTenant,
  useStructures,
  useAssets,
  utils,
  useLocalizations,
} from '@/core'
import { getActivityProgress } from '@/services/progress'

import Topbar from '@/components/Topbar'
import Card from '@/components/Card'
import Curtain from '@/components/Curtain'
import { useI18n } from 'vue-i18n'
import useTranslations from '@/composables/useTranslations'

export default {
  name: 'Arena',

  components: {
    Topbar,
    Card,
    Curtain,
  },

  setup() {
    const route = useRoute()
    const { tenant } = useTenant()
    const {
      getStructuresBySlugPath,
      fetchStructuresNode,
      fetchStructuresChildren,
      getStructuresChildrens,
    } = useStructures()
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils

    const arenaSlug = computed(() => route.params.arena)
    const arenaSlugPath = computed(
      () => `${tenant.value.slug}/arenas/${arenaSlug.value}`,
    )

    const arena = ref()
    const activities = ref(null)
    const activitiesProgress = ref({})
    const arenaTitle = computed(() => (arena.value && arena.value.name) || '')
    const cover = ref({})
    const countMap = ref({})
    const enabledActivities = ref({})
    const asyncDataReady = ref(false)
    const { locale } = useI18n()
    const { isFallbackLanguage } = useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()

    useHead({
      title: computed(() => `${arenaTitle.value} - ${tenant.value.name}`),
    })

    onMounted(async () => {
      //fetch arena
      await fetchStructuresNode(arenaSlugPath.value)

      const arenaInit = getStructuresBySlugPath(
        arenaSlugPath.value,
        true,
        'DIRECTORY',
      )
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(arenaInit.id, locale.value)
        }
      } catch {
        //
      }

      arena.value = getStructuresBySlugPath(
        arenaSlugPath.value,
        true,
        'DIRECTORY',
        locale.value,
      )

      // fetch activities
      await fetchStructuresChildren(arenaSlugPath.value, {
        limit: 100,
        filter: '{"type": "DIRECTORY"}',
      })
      // fetch cover
      await fetchAsset(arena.value.cover)
      cover.value = addCloudinaryUrlParams(
        getAssetById(arena.value.cover).url,
        'w_560',
      )
      const allActivities = getStructuresChildrens(arena.value.id, 'DIRECTORY')

      try {
        if (!isFallbackLanguage.value) {
          await Promise.all(
            allActivities.map(async act => {
              try {
                await fetchStructuresLocalization(act.id, locale.value)
              } catch {
                //
              }
            }),
          )
        }
      } catch {
        //
      }
      activities.value = getStructuresChildrens(
        arena.value.id,
        'DIRECTORY',
        locale.value,
      )
      activities.value.forEach(
        item => (enabledActivities.value[item.id] = true),
      )

      let allPreviousActivitiesDone = true

      for (const [i, item] of activities.value.entries()) {
        const ap = await getActivityProgress(item.id)
        countMap.value[item.id] = (
          await getStructuresChildren(tenant.value.namespace, item.id, {
            limit: 1,
            filter: '{"type": "OBJECT"}',
          })
        ).pagination.total
        activitiesProgress.value[item.id] = ap

        const isLastActivity = !activities.value[i + 1]
        if (!isLastActivity) {
          allPreviousActivitiesDone = ap.done
            ? allPreviousActivitiesDone
            : false
        } else {
          if (!allPreviousActivitiesDone) {
            enabledActivities.value[item.id] = false
            item.name = null
            item.cover = 'hidden'
          }
        }
      }

      asyncDataReady.value = true
    })

    return {
      arenaSlug,
      arena,
      cover,
      countMap,
      activities,
      activitiesProgress,
      enabledActivities,
      asyncDataReady,
    }
  },
}
</script>
